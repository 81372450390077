video.promotion {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-bottom: 150px;
    max-width: 1200px;
    margin: 0 auto;
  }
  