.bm-item-list {
    margin-top: 25px;
}
.bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    font-size: 20px;
    color: dodgerblue;
    transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
    color: orange;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 36px;
    height: 30px;
    right: 15px;
    top: -70px;
}

@media(max-height: 576px) {
    .bm-burger-button {
        top: -50px;
    }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #ff8d00;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 36px;
    width: 36px;
}

/* Color/shape of close button cross */
.bm-cross-button {
    position: absolute !important;
    right: 15px !important;
    top: -90px !important;
    width: 15px;
    height: 15px;
    display: flex !important;
    justify-content: center !important;
    align-content: center !important;
}
.bm-cross {
    background: silver;
}

/* General sidebar styles */
.bm-menu {
    position: absolute;
    right: 0;
    top: -100px;
    width: 100%;
    height: 100%;
    background: white;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    overflow: auto;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
